import styled from "styled-components";
import {CategoriesWrapper} from "components/CategoryList/CategoryList.style";
import {ProductsWrapper} from "components/ProductList/ProductList.style";

export const SearchWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  flex: 1;

  .dropdown-for-quick-answers {
    white-space: break-spaces;
    text-align: center;

    position: absolute;
    z-index: 10;
    width: 100vw;
    overflow: auto;

    transition: 0.7s max-height cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.9s cubic-bezier(0.73, 0.32, 0.34, 1.5) opacity;

    
    .dropdown-for-quick-answers-container {
      max-width: 1000px;
      display: flex;
      justify-content: center;

      .dropdown-for-quick-answers-text {
        border-radius: 40px;
        background: ${({theme: {colors}}) => colors.white};
        padding-block: 20px;
        padding-inline: 32px;

        line-height: 26px;
        text-align: center;
        width: auto;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
      margin-inline-end: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 32px;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 32px;
    }

    &.opened-dropdown {
      max-height: 460px;
      opacity: 1;
    }

    &.closed-dropdown {
      max-height: 0;
      opacity: 0;
    }

    .quick-result {
      display: flex;
      cursor: pointer;
      align-items: center;
      min-height: 40px;

      ${({ theme: { fonts } }) => fonts.subheaderS};

      margin-bottom: 20px;

      img {
        width: 40px;
        height: 40px;
        flex-shrink: 0;

        margin-inline-end: 20px;
        border-radius: 8px;
      }
    }
    

    padding: 0 415px 0 573px;
    
    @media (max-width: 1440px) {
      padding: 0 390px 0 553px;
    }

    @media (max-width: 1280px) {
      padding: 0 378px 0 514px;
    }

    @media (max-width: 1160px) {
      padding: 0px 367px 0px 472px;
    }

    @media (max-width: 1080px) {
      padding: 0px 334px 0px 472px
    }
  }

  .product-list-wrapper {
    max-width: 1000px;
    width: 100%;

    display: flex;
    flex-direction: column;
    padding-inline-start: 62px;

    height: calc(100vh - 105px);

    ${ProductsWrapper} {
      //margin-top: 40px;
      margin-inline-end: 0;
    }
  }

  .category-list-wrapper {
    max-width: 1000px;

    display: flex;
    flex-direction: column;
    margin-top: 40px;

    ${CategoriesWrapper} {
      margin: 0;
    }
  }
`;
