import {useGetLocale, useTranslate} from "@refinedev/core";
import React, {useEffect, useState} from "react";
import {KeyBoardOpenButtonWrapper, KeyBoardWrapper} from "./CustomKeyBoard.style";
import Keyboard from "react-simple-keyboard";
import 'react-simple-keyboard/build/css/index.css';
import {useGlobalSettings, useUI} from "context";


const arabicLayout = {
  default: [
    "\u0630 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "\u0636 \u0635 \u062B \u0642 \u0641 \u063A \u0639 \u0647 \u062E \u062D \u062C \u062F \\",
    "\u0634 \u0633 \u064A \u0628 \u0644 \u0627 \u062A \u0646 \u0645 \u0643 \u0637",
    "{shift} \u0626 \u0621 \u0624 \u0631 \u0644\u0627 \u0649 \u0629 \u0648 \u0632 \u0638",
    "{lang} {close} {space}",
  ],
  shift: [
    "\u0651 ! @ # $ % ^ & * ) ( _ + {bksp}",
    "\u064E \u064B \u064F \u064C \u0644\u0625 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |",
    '\u0650 \u064D ] [ \u0644\u0623 \u0623 \u0640 \u060C / : "',
    "{shift} ~ \u0652 } { \u0644\u0622 \u0622 \u2019 , . \u061F",
    "{lang} {close} {space}",
  ],
};

const russianLayout = {
  'default': [
    '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    'й ц у к е н г ш щ з х ъ',
    'ф ы в а п р о л д ж э',
    '{shift} я ч с м и т ь б ю .',
    '{lang} {close} {space}'
  ],
  'shift': [
    '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
    'Й Ц У К Е Н Г Ш Щ З Х Ъ',
    'Ф Ы В А П Р О Л Д Ж Э',
    '{shift} Я Ч С М И Т Ь Б Ю ,',
    '{lang} {close} {space}'
  ]
}

const serbianCyrLayout = {
  'default': [
    '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    'љ њ е р т з у и о п ш ђ',
    'а с д ф г х ј к л ч ћ ж',
    '{shift} ѕ џ ц в б н м .',
    '{lang} {close} {space}'
  ],
  'shift': [
    '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
    'Љ Њ Е Р Т З У И О П Ш Ђ',
    'А С Д Ф Г Х Ј К Л Ч Ћ Ж',
    '{shift} Ѕ Џ Ц В Б Н М .',
    '{lang} {close} {space}'
  ]
}

const serbianLatLayout = {
  'default': [
    '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    'q w e r t z u i o p š đ',
    'a s d f g h j k l č ć ž',
    '{shift} y x c v b n m .',
    '{lang} {close} {space}'
  ],
  'shift': [
    '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
    'Q W E R T Z U I O P Š Đ',
    'A S D F G H J K L Č Ć Ž',
    '{shift} Y X C V B N M .',
    '{lang} {close} {space}'
  ]
}


const englishLayout = {
  'default': [
    '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    'q w e r t y u i o p',
    'a s d f g h j k l',
    '{shift} z x c v b n m , .',
    '{lang} {close} {space}'
  ],
  'shift': [
    '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
    'Q W E R T Y U I O P',
    'A S D F G H J K L',
    '{shift} Z X C V B N M , .',
    '{lang} {close} {space}'
  ]
}

interface ICustomKeyBoard {
  value?: string
  keyboardRef?: any
  onChange: (value: string) => void
}

const langs: any = {
  'en': {value: 'Eng', layout: englishLayout},
  'ar': {value: 'Arb', layout: arabicLayout},
  'ru': {value: 'Rus', layout: russianLayout},
  'sr_cyr': {value: 'SrpCyr', layout: serbianCyrLayout},
  'sr_lat': {value: 'SrpLat', layout: serbianLatLayout}
}


export const CustomKeyBoard: React.FC<ICustomKeyBoard> = ({ onChange, value = '', keyboardRef = null }) => {
  const locale = useGetLocale();
  const currentLocale = locale();
  const { settings } = useGlobalSettings();

  const keyboardLangCodes: any = settings?.languages?.reduce((obj: any, l: string) => (l === 'sr' ? {
    ...obj,
    ['sr_cyr']: langs['sr_cyr'],
    ['sr_lat']: langs['sr_lat']
  } : {
    ...obj,
    [l]: langs[l]
  }), {})

  const [keyBoardLang, setKeyBoardLang] = useState<string>(currentLocale === 'sr' ? 'sr_cyr' : currentLocale || 'en')
  const [layoutName, setLayoutName] = useState<string>('default');

  const { displayKeyBoard, openKeyBoard, closeKeyBoard } = useUI();

  useEffect(() => {
    if (value) {
      keyboardRef.current?.setInput(value);
    }
    keyboardRef.current?.setCaretPosition(null, null, "default")
  }, [value])

  const handleKeyPress = (e: string) => {
    if (e === '{lang}') {
      const langCodes = Object.keys(keyboardLangCodes)
      const selectLangCodeIndex = langCodes.indexOf(keyBoardLang)
      setKeyBoardLang(langCodes[selectLangCodeIndex + 1 >= langCodes.length ? 0 : selectLangCodeIndex + 1])
    }

    if (e === '{shift}') {
      handleShift()
    }

    if (e === '{close}') {
      closeKeyBoard()
    }
  }

  const handleShift = () => {
    setLayoutName(layoutName => layoutName === 'default' ? 'shift' : 'default');
  };

  return (
    <>
      <KeyBoardOpenButtonWrapper onClick={openKeyBoard}/>

      <KeyBoardWrapper className={`keyboard-container ${!displayKeyBoard ? '' : 'opened-keyboard'}`}>
        <Keyboard
          keyboardRef={(r) => {
            if (keyboardRef) {
              keyboardRef.current = r
            }
          }}
          onChange={(e) => {
            onChange(e)
          }}
          onKeyPress={handleKeyPress}
          layoutName={layoutName}
          layout={keyboardLangCodes?.[keyBoardLang]?.layout || englishLayout}
          display={{
            '{lang}': keyboardLangCodes?.[keyBoardLang]?.value || 'Eng',
            '{bksp}': 'Backspace',
            '{close}': ' ',
            '{space}': ' ',
            '{shift}': 'Shift'
          }}
          buttonTheme={[
            {
              class: 'language-button',
              buttons: '{lang}'
            },
            {
              class: 'backspace-button',
              buttons: '{bksp}'
            },
            {
              class: 'close-button',
              buttons: '{close}'
            }
          ]}
        />
      </KeyBoardWrapper>
    </>
  );
};
